
import { defineComponent, reactive, toRefs } from 'vue';

import AppIcon from '@/components/stateless/AppIcon.vue';
import AppSelect from '@/components/stateless/AppSelect.vue';
import AppSelectSecondary from '@/components/stateless/AppSelectSecondary.vue';

  import { IOption } from '@/types';


export default defineComponent({
  name: 'Selects',

  components: { AppSelect, AppSelectSecondary, AppIcon },

  setup() {
    const state = reactive({
      selectedValue: { value: 'Option1', label: 'Option1' },
      selectedValues: [],
      selectedValues2: { iconName: 'finance', id: '1' }
    });
    const optionsCategoriesList = reactive<any[]>([
      { iconName: 'finance', id: '1' },
      { iconName: 'career-professional', id: '2' },
      { iconName: 'health-wellbeing', id: '3' },
      { iconName: 'school-engagement', id: '4' },
      { iconName: 'community-engagement', id: '5' },
      { iconName: 'parenting', id: '6' },
      { iconName: 'other', id: '7' }
    ]);

    const optionsList = reactive<IOption[]>([
      { value: 'Option1', label: 'Option1' },
      { value: 'Option2', label: 'Option2' },
      { value: 'Option3', label: 'Option3' },
      { value: 'Option4', label: 'Option4' },
      { value: 'Option6', label: 'Option5' },
      { value: 'Option7', label: 'Optio6' },
    ]);

    const { selectedValue, selectedValues, selectedValues2 } = toRefs(state);
    return { selectedValue, selectedValues, optionsList, optionsCategoriesList, selectedValues2 };
  }
});
