<template>
  <div class="text-primary text-3xl text-center p-7 border-b border-grey-fp-30">Select</div>
  <div class="py-20 max-w-300">
    <p class="mb-10 text-grey-fp-80 text-1xl">Normal</p>
    <AppSelect
      v-model="selectedValue"
      :options="optionsList"
      class="mb-18"
    />
    <p class="mb-10 text-grey-fp-80 text-1xl">Disabled</p>
    <AppSelect
      v-model="selectedValue"
      disabled
      :options="optionsList"
      class="mb-18"
    />
    <p class="mb-10 text-grey-fp-80 text-1xl">Error</p>
    <AppSelect
      v-model="selectedValue"
      :options="optionsList"
      error="Error message"
      class="mb-25"
    />
    <p class="mb-10 text-grey-fp-80 text-1xl">Multiple</p>
    <AppSelectSecondary
      v-model="selectedValues"
      :options="optionsList"
      multiple
      class="mb-25"
    />
    <p class="mb-10 text-grey-fp-80 text-1xl">Icon</p>
    <AppSelectSecondary
      v-model="selectedValues2"
      :options="optionsCategoriesList"
      itemKey="id"
      class="mb-25"
    > 
      <template #selection="{ value }">
        <div class="flex items-center justify-center w-30 h-30 bg-primary rounded-full">
          <AppIcon :name="value.iconName" size="20" class="text-white" />
        </div>
      </template>

      <template #options="{ item }">
        <div class="flex items-center justify-center w-30 h-30 bg-primary rounded-full">
          <AppIcon :name="item.iconName" size="20" class="text-white" />
        </div>
      </template>
    </AppSelectSecondary>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue';

import AppIcon from '@/components/stateless/AppIcon.vue';
import AppSelect from '@/components/stateless/AppSelect.vue';
import AppSelectSecondary from '@/components/stateless/AppSelectSecondary.vue';

  import { IOption } from '@/types';


export default defineComponent({
  name: 'Selects',

  components: { AppSelect, AppSelectSecondary, AppIcon },

  setup() {
    const state = reactive({
      selectedValue: { value: 'Option1', label: 'Option1' },
      selectedValues: [],
      selectedValues2: { iconName: 'finance', id: '1' }
    });
    const optionsCategoriesList = reactive<any[]>([
      { iconName: 'finance', id: '1' },
      { iconName: 'career-professional', id: '2' },
      { iconName: 'health-wellbeing', id: '3' },
      { iconName: 'school-engagement', id: '4' },
      { iconName: 'community-engagement', id: '5' },
      { iconName: 'parenting', id: '6' },
      { iconName: 'other', id: '7' }
    ]);

    const optionsList = reactive<IOption[]>([
      { value: 'Option1', label: 'Option1' },
      { value: 'Option2', label: 'Option2' },
      { value: 'Option3', label: 'Option3' },
      { value: 'Option4', label: 'Option4' },
      { value: 'Option6', label: 'Option5' },
      { value: 'Option7', label: 'Optio6' },
    ]);

    const { selectedValue, selectedValues, selectedValues2 } = toRefs(state);
    return { selectedValue, selectedValues, optionsList, optionsCategoriesList, selectedValues2 };
  }
});
</script>